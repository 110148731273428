import React from 'react';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { ThemeProvider } from './src/context/themecontext';
import { ApiProvider } from './src/context/apicontext';
import { SessionProvider } from './src/context/sessioncontext';

export const wrapPageElement = ({ element }) => {
	return (
		<React.Fragment>
			{element}
			<ToastContainer
				position='bottom-center'
				autoClose={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
			/>
		</React.Fragment>
	);
};

export const wrapRootElement = ({ element }) => {
	return (
		<ThemeProvider>
			<ApiProvider>
				<SessionProvider>
					{element}
				</SessionProvider>
			</ApiProvider>
		</ThemeProvider>
	);
};
