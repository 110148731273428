import React from 'react';

import Alert from 'react-bootstrap/Alert';

import { useStateWithStorage } from '../utils/storage';

const api = process.env.GATSBY_API_URL;

const defaultPayload = {
	method: 'GET', // GET, POST, PUT, DELETE
	cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
	credentials: 'omit', // include, *same-origin, omit
	headers: { 'Content-Type': 'application/json' },
	redirect: 'follow', // manual, *follow, error
	referrer: 'client', // no-referrer, *client
};

const defaultApi = {
	ready: '',
	fetch: () => {},
	warn: () => {}
};

export const ApiContext = React.createContext(defaultApi);

export const ApiProvider = (props) => {
	const { children } = props;
	const [ready, setReady] = useStateWithStorage('apiReady', undefined);

	async function apiTest() {
		return new Promise(resolve => {
			if (ready) {
				resolve(true);
			}
			else if (api === '') {
				resolve(false);	// API not defined in local environment
			}
			else {
				fetch(`${api}test`)
					.then(response => response.json())
					.then(result => {
						if (result.response === 1) {
							resolve(true);
							setReady(true);
						}
						else {
							resolve(false); // API server likely disabled remotely
						}
					})
					.catch(error => {
						resolve(false);	// Likely network error preventing connection to API
						console.log(error);
					});
			}
		});
	}

	const apiFetch = (route, input, output) => {
		apiTest().then(apiReady => {
			if (apiReady) {
				const payload = {...defaultPayload, ...input};
				fetch(`${api}${route}`, payload)
					.then(response => response.json())
					.then(result => output && output(result))
					.catch(error => {
						if (output) output({response: -1, message: error});
						setReady(undefined);
						console.log(error.message);
					});
			}
			else {
				if (output) output({response: -1, message: 'API unavailable'});
			}
		});
	};

	const apiWarn = (message = 'Please try again later.') => {
		if (ready) return (<></>);
		apiTest();
		return (
			<Alert variant='warning'>
				The Tower of the Hand API is currently unavailable.
				{` `}
				<span dangerouslySetInnerHTML={{ __html: message }} />
			</Alert>
		);
	};

	const providerValue = { ready, fetch: apiFetch, warn: apiWarn };

	return (
		<ApiContext.Provider value={providerValue}>
			{children}
		</ApiContext.Provider>
	);
};