import React from 'react';

import { ApiContext } from '../context/apicontext';
import { useStateWithStorage } from '../utils/storage';

const defaultSession = {
	session: '',
	setSession: () => {},
	user: undefined,
	setUser: () => {}
};

export const SessionContext = React.createContext(defaultSession);

export const SessionProvider = (props) => {
	const Api = React.useContext(ApiContext);
	const { children } = props;
	const [session, setSession] = useStateWithStorage('session', '', { rememberForever: true });
	const [user, setUser] = useStateWithStorage('user', undefined);

	React.useEffect(() => {
		// Listen for session changes from other tabs
		window.addEventListener('storage', (e) => {
			if (e.key === 'session') {
				// Log out
				if (e.oldValue !== '' && !e.newValue) {
					setSession('');
				}
				// Session changed (e.g. log in)
				else if (e.newValue) {
					setSession(JSON.parse(e.newValue));
				}
			}
		});
	}, [setSession]);

	React.useEffect(() => {
		if (session !== '' && !user) {
			const headers = {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${session}`
			};
			Api.fetch('user/public',
				{ headers },
				(result) => {
					if (result.response === 1)
						setUser(result.user);
					// Response == 0: No valid session match => Clear session
					else if (result.response === 0)
						setSession('');
					// Response == -1: API unavailable. Do not clear session
				}
			);
		}
		else if (session === '' && user) {
			setUser(undefined);
		}
	}, [Api, session, setSession, user, setUser]);

	const providerValue = { session, setSession, user, setUser };

	return (
		<SessionContext.Provider value={providerValue}>
			{children}
		</SessionContext.Provider>
	);
};