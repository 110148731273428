import React from 'react';

const windowGlobal = typeof window !== 'undefined' && window;

const StorageDefaultOptions = {
	rememberForever: false,		// We always store in session; we can also store in local storage if told to remember forever
	useDefault: false,			// Set to true to use default value as initial value instead of any stored value
	useAndStoreDefault: false,	// Set to true to use default and store it immediately to avoid render loops
};

export const useStateWithStorage = (itemKey, itemDefault, options) => {
	if (!options) options = StorageDefaultOptions;

	// Set initial value in state
	const [value, setValue] = React.useState(() => {
		// Use default value if requested
		if (options.useAndStoreDefault) {
			storeItem(itemKey, itemDefault, options.rememberForever);
			return itemDefault;
		}
		if (options.useDefault) return itemDefault;
		return getStoredItem(itemKey, itemDefault, options.rememberForever);
	});

	// Update stored value when value changed in state
	React.useEffect(() => {
		// Remove from store (or ignore) if new value is undefined or default
		if (value === undefined || value === itemDefault) {
			removeStoredItem(itemKey);
		}
		else {
			storeItem(itemKey, value, options.rememberForever);
		}
	}, [value, itemKey, itemDefault, options]);

	return [value, setValue];
};

// Use JSON.stringify and JSON.parse to preserve item types when storing, getting
const storeItem = (itemKey, itemValue, useLocalStorage) => {
	if (windowGlobal && windowGlobal.sessionStorage)
		windowGlobal.sessionStorage.setItem(itemKey, JSON.stringify(itemValue));
	if (windowGlobal && windowGlobal.localStorage && useLocalStorage) {
		windowGlobal.localStorage.setItem(itemKey, JSON.stringify(itemValue));
	}
	// Remove locally stored item if local storage no longer needed, but item currently saved there
	else if (windowGlobal && windowGlobal.localStorage) {
		windowGlobal.localStorage.removeItem(itemKey);
	}
};
const getStoredItem = (itemKey, itemDefault, useLocalStorage) => {
	// Always use session value if set, even if set to remember forever
	let storedValue = windowGlobal && windowGlobal.sessionStorage && windowGlobal.sessionStorage.getItem(itemKey);
	if (storedValue) return JSON.parse(storedValue);
	if (windowGlobal && windowGlobal.localStorage && useLocalStorage) {
		storedValue = windowGlobal.localStorage.getItem(itemKey);
		if (storedValue) return JSON.parse(storedValue);
	}
	return itemDefault;
};
const removeStoredItem = (itemKey) => {
	if (windowGlobal && windowGlobal.sessionStorage)
		windowGlobal.sessionStorage.removeItem(itemKey);
	if (windowGlobal && windowGlobal.localStorage)
		windowGlobal.localStorage.removeItem(itemKey);
};
