import React from 'react';

import { useStateWithStorage } from '../utils/storage';

const defaultTheme = {
	theme: '',
	preference: '',
	setPreference: () => {},
};

export const ThemeContext = React.createContext(defaultTheme);

// Getting dark mode information from OS!
//	You need macOS Mojave + Safari Technology Preview Release 68 to test this currently.
const supportsDarkMode = () => {
	return window.matchMedia('(prefers-color-scheme: dark)').matches === true;
};

export const ThemeProvider = (props) => {
	const { children } = props;
	const [preference, setPreference] = useStateWithStorage('themePreference', '', { rememberForever: true });
	const [theme, setTheme] = useStateWithStorage('theme', '');

	React.useEffect(() => {
		// Listen for theme changes from other tabs
		window.addEventListener('storage', (e) => {
			if (e.key === 'themePreference') {
				const newTheme = JSON.parse(e.newValue);
				if (newTheme === 'auto')
					setPreference('auto');
				else if (newTheme === 'dark')
					setPreference('dark');
				else
					setPreference('light');
			};
		});
	}, [setPreference]);

	React.useEffect(() => {
		if (preference === 'auto')
			setTheme(supportsDarkMode() ? 'dark' : 'light');
		else
			setTheme(preference === 'dark' ? 'dark' : 'light');
	}, [preference, setTheme]);

	const providerValue = { theme, preference, setPreference };

	return (
		<ThemeContext.Provider value={providerValue}>
			{children}
		</ThemeContext.Provider>
	);
};